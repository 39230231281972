var core = require('org_km/product/tile');

function updateTransactionalElements() {
    $('body').on('productTile:afterSwatchSwitch', (_event, params) => {
        var selectedPid = $(params.swatch).data('wishlistpid');

        if (!selectedPid) {
            return false;
        }

        var $container = params.tileParent;

        if (!window.UncachedData.isTransactionalContext) {
            return false;
        }

        var url = $container.data('transactional-url');

        $.ajax({
            url: url + '?pid=' + selectedPid,
            method: 'GET',
            success: data => {
                if (data.success) {
                    if (!data.isPurchasable) {
                        $container.find('[data-transactional-element]').addClass('d-none');
                        $container.find('[data-non-transactional-element]').removeClass('d-none');
                    } else {
                        $container.find('[data-transactional-element]').removeClass('d-none');
                        $container.find('[data-non-transactional-element]').addClass('d-none');
                    }
                }
            },
            error: () => {
                // don't change product tile in the case of an error
            }
        });
    });
}

core.updateTransactionalElements = updateTransactionalElements;

module.exports = core;
