'use strict';

var cart = require('./cart/cart');
var processInclude = require('base/util');
var superModule = require('org_km/cart');


superModule.baseFiles.cart = function () {
    return cart.init.call(cart);
}

module.exports = {
    baseFiles: superModule.baseFiles,
    loadFunctions: function () {
        Object.keys(superModule.baseFiles).forEach(function (key) {
            processInclude(superModule.baseFiles[key]);
        });
    }
};