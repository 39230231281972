'use strict';

var core = require('org_km/cart/cart');
var base = require('org_km/product/base');

core.renderShippingMethods = function (html) {
    var $shippingMethodsSelect = $('#shippingMethods')
    $shippingMethodsSelect.replaceWith(html);
}

core.init = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().addClass('cart-empty').append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h2>' + data.basket.resources.emptyCartMsg + '</h2> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    var countUpdateObj = {};
                    countUpdateObj.quantityTotal = data.basket.numItems;
                    countUpdateObj.minicartCountOfItems = data.basket.resources.minicartCountOfItems;
                    $('.minicart').trigger('count:update', countUpdateObj);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.shouldReload) {
                        location.reload();
                    }

                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).closest('.card').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                    if (data.shippingMethodSelectionHtml) {
                        module.exports.renderShippingMethods(data.shippingMethodSelectionHtml);
                    }
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.product-info .quantity-stepper input, .quantity-form > .quantity-stepper input, .quantity-form > .requested-quantity', function() {
        var $quantityForm = $(this).closest('.quantity-form');
        var url = $quantityForm.data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $quantityForm.data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $quantityForm.data('pid');
        var uuid = $quantityForm.data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $quantityForm.parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: $quantityForm,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();

                var basketLineItem = data.items.find(item => item.UUID === uuid);
                var isQuantityBackordered = Boolean(basketLineItem.quantityOptions.quantityBackordered) && basketLineItem.quantityOptions.quantityBackordered > 0;
                var $productLineItemCard = $(`.uuid-${uuid}`);
                var $quantityRequestedStepperComponent = $productLineItemCard.find('.qty-requested-stepper');
                if (isQuantityBackordered && !(Boolean($quantityRequestedStepperComponent.length))) {
                    location.reload();
                }

                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.basketModel.numItems) && currentItemCount !== data.basketModel.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.basketModel.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.basketModel.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.basketModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.basketModel);
                        module.exports.updateApproachingDiscounts(data.basketModel.approachingDiscounts);
                        module.exports.validateBasket(data.basketModel);
                        $('body').trigger('promotion:success', data.basketModel);
                        $('.coupon-code-field').val('');
                        if (data.newBonusDiscountLineItem) {
                            base.methods.chooseBonusProducts(data.newBonusDiscountLineItem);
                        }
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    if (data.plisRemovedBonusDiscountLineItems && data.plisRemovedBonusDiscountLineItems.length > 0) {
                        data.plisRemovedBonusDiscountLineItems.forEach((uuid) => {
                            $(`.uuid-${uuid} .bonus-product-button`).remove();
                        });
                    }
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('quantityStepper:change', function (e, qtyStepper) {
        let $modal = $('.modal.show .update-cart-url');
        if ($modal.length > 0) {
            var selectedQuantity = $(qtyStepper).find('input').val();
            $modal.data('quantity', selectedQuantity);
        }
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        if (data.shippingMethodSelectionHtml) {
                            module.exports.renderShippingMethods(data.shippingMethodSelectionHtml);
                        }

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    $('body').on('cart:update', function (event, data) {
        var treeHtmlEntity = ' &#x1F333; ';
        var numberOfTreesSaved = data.numberOfTreesSaved || data.basket.numberOfTreesSaved || 0;
        var trees = treeHtmlEntity.repeat(numberOfTreesSaved);

        $('[data-select-trees-container]').html(trees)
    });
}

module.exports = core;