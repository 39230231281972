var main = require('integrations/main');

main.baseFiles.tile = require('./product/tile');
main.baseFiles.base = require('./product/base');
main.baseFiles.dragSwitch = require('./components/dragSwitch');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.cart = require('./cart');
main.baseFiles.storeLocator = require('./storeLocator/storeLocator');
main.baseFiles.header = require('./components/header');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.footer = require('./components/footer');

module.exports = main;
