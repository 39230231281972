"use strict";

var core = require('integrations/integrations/klaviyo/identify');

function toggleBuyButtons($container, action) {
    var $addToCartBtn = $container.find('.add-to-cart');
    var $sfPayBtns = $container.find('.sfpay-buttons');

    if (action == 'hide') {
        !$addToCartBtn.hasClass('d-none') ? $addToCartBtn.addClass('d-none') : '';
        !$sfPayBtns.hasClass('d-none') ? $sfPayBtns.addClass('d-none') : '';
    } else if (action == 'show') {
        $addToCartBtn.hasClass('d-none') ? $addToCartBtn.removeClass('d-none') : '';
        $sfPayBtns.hasClass('d-none') ? $sfPayBtns.removeClass('d-none') : '';
    }
}

/**
 * Enables user to click out of stock swatches and opt in to be notified via klaviyo email when back in stock
 * -- override from core to handle salesforce pay buttons --
 */
core.nonClickableAttribute = function () {
    $('body').on('product:swatchClicked', function (e, attr, toggleObject){
        var attribute = $(attr);
        var validUrl = attribute.attr('data-url');
        var viewOutOfStockItems = toggleObject.viewOutOfStockItems;

        if (viewOutOfStockItems && validUrl && validUrl !== 'null' && validUrl !== undefined) {

            var $productContainer = attribute.closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = attribute.closest('.product-detail');
            }

            var $notifyBISBtn = $productContainer.find('[name="backInStockForm"]');

            //remove old form if it exists
            $notifyBISBtn.length ? $notifyBISBtn.remove() : '';

            // only use an attribute that's out of stock
            if (attribute.find('span[class*="-value"]').hasClass('available')) {
                toggleBuyButtons($productContainer, 'show');
                return;
            }

            var actionUrl = $('#kl_back_in_stock_area').val();
            if (actionUrl) {
                $.ajax({
                    url: actionUrl + '?' + validUrl,
                    type: 'post',
                    data: validUrl,
                    dataType: "json",
                    success: function (data) {
                        var $addToCartBtn = $productContainer.find('.add-to-cart');
                        toggleBuyButtons($productContainer, 'hide');
                        $addToCartBtn.parent().prepend(data.template);
                    },
                    error: function () {
                        toggleBuyButtons($productContainer, 'show');
                        return;
                    }
                });
            }
        }
    });
};

/**
 * Show form on page load if applicable to allow user to opt in to be notified via klaviyo email when back in stock
 */
core.showFormIfApplicable = function () {
    $(window).on('load', function () {
        // check on PDPs only
        if ($('[data-action="Product-Show"]').length || $('[data-action="Product-ShowInCategory"]').length) {
            var $productContainers = $('.set-items').length > 0 ? $('.set-items').find('.product-detail') : $('.product-detail');

            $productContainers.each((_i, productContainer) => {
                var $productContainer = $(productContainer);
                var $selectedAttribute = $productContainer.find('.attribute .selected');

                if (!$selectedAttribute.length || $selectedAttribute.hasClass('available')) {
                    return;
                }

                var actionUrl = $('#kl_back_in_stock_area').val();
                var variantUrl = $selectedAttribute.closest('[data-url]').data('url');
                if (actionUrl && variantUrl) {
                    $.ajax({
                        url: actionUrl + '?' + variantUrl,
                        type: 'post',
                        data: variantUrl,
                        dataType: "json",
                        success: data => {
                            var $formContainer = $productContainer.find('.cart-and-ipay > div');
                            toggleBuyButtons($productContainer, 'hide');
                            $formContainer.append(data.template);
                        },
                        error: () => {
                            return;
                        }
                    });
                }
            });
        }
    });
}

module.exports = core;
