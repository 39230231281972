var core = require('org_km/product/base');

function updateTransactionalElements() {
    $('body').on('product:afterAttributeSelect', (_event, params) => {
        var isPurchasable = !!params.data.product.kmPurchasable;
        var isQuickview = params.container.hasClass('product-quickview');
        var $container = isQuickview ? params.container.closest('.modal-content') : params.container;

        if (!isPurchasable || !window.UncachedData.isTransactionalContext) {
            $container.find('[data-transactional-element]').addClass('d-none');
            $container.find('[data-non-transactional-element]').removeClass('d-none');
        } else {
            $container.find('[data-transactional-element]').removeClass('d-none');
            $container.find('[data-non-transactional-element]').addClass('d-none');
        }
    });
}

core.updateTransactionalElements = updateTransactionalElements;

module.exports = core;
