'use strict';

var core = require('core/components/slider');

var tinySlider = require('tiny-slider/src/tiny-slider');
var zoom = require('jquery-zoom');
var imagesLoaded = require('imagesloaded');
require('jquery.scrollintoview');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

var toggleZoomClass = function() {
    $(this).toggleClass('zoomed');
}

// Override core to zoom on click instead of hover
core.enableImageZoom = function($sliderContainer) {
    if ($sliderContainer.closest('.primary-images').length
        && !$sliderContainer.hasClass('slider-thumbnails')
        && !window.isMobile()) {
        var $slides = $sliderContainer.find('.slide');
        $slides.each((_index, slide) => {
            var $slide = $(slide);
            var $zoomContainer = $slide.find('.zoom-image-container');

            if (!$slide.hasClass('no-zoom')) {
                var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
                $zoomContainer.zoom({
                    url: zoomImageUrl,
                    magnify: 1,
                    touch: false,
                    on: 'click',
                    onZoomIn: toggleZoomClass,
                    onZoomOut: toggleZoomClass
                });
            }
        });
    }
}

// Override to wrap images in a div with class zoom-image-container
core.getUpdatedSlideItems = function(images, assets, isThumbnail, product) {
    var slideItems = [];

    var $slider = $('.primary-images-main .slider');
    if ($slider.length) {
        $slider.empty();
    }

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');
        var $imageContainer = $slideElement;

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);

            $imageContainer = $('<div></div>').attr('class', 'zoom-image-container');
            $slideElement.append($imageContainer);
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        $imageContainer.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    if (product && product.videoHtml) {
        var $videoSlide = $('<div></div>').attr('class', 'pdp-gallery-asset pdp-video-slide slide no-zoom');
        var $videoContainer = $('<div></div>').attr('class', 'pdp-video-container').html(product.videoHtml);
        $videoSlide.append($videoContainer);
        slideItems.push($videoSlide[0]);

        var $videoThumbnailSlide = $('<div></div>').attr('class', 'pdp-gallery-asset slide video-container');
        var $thumbnailImage = $('<img/>').attr({
            'src': product.videoThumbnail,
            'alt': 'Video thumbnail',
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });
        $videoThumbnailSlide.append($thumbnailImage).append('<span class="video-thumbnail-button"></span>');
        slideItems.push($videoThumbnailSlide[0]);
    }

    core.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
};


// Handle Pause/Play Button
core.bindPauseButton = function($sliderContainer, slider) {
    var sliderButtonPlacement = $sliderContainer.attr('data-slider-pause-button-placement');

    if ($sliderContainer[0].hasAttribute('data-slider-autoplay') && $sliderContainer.attr('data-slider-autoplay') != 'false' && $sliderContainer[0].hasAttribute('data-slider-pause-button-placement') && $sliderContainer.attr('data-slider-pause-button-placement') != "none")  {
        if (sliderButtonPlacement == 'with-pagination') {
            $sliderContainer.find('.tns-nav').addClass('with-pagination-btn').append('<a href="#" class="tns-nav-controller" tabindex="0" aria-label="stop and start slider"><span class="sr-only">stop and start slider</span></a>');
        } else {
            $sliderContainer.prepend('<a href="#" class="tns-nav-controller" tabindex="0" aria-label="stop and start slider"><span class="sr-only">stop and start slider</span></a>');
        };

        $('.tns-nav-controller').addClass(sliderButtonPlacement);

        $sliderContainer.on('click', '.tns-nav-controller', function() {
            event.preventDefault();
            $(this).toggleClass('pause');
            $sliderContainer.toggleClass('slider-pause');

            if ($sliderContainer.hasClass('slider-pause')) {
                slider.pause();
            } else {
                slider.play();
            }
        });

        $sliderContainer.on('keyup', '.tns-nav-controller', function(event) {
            if (event.keyCode === 13) {
                $(".tns-nav-controller").click();
            }
        });
    }
};

core.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');
        var updatedSlideItems = data !== undefined ? core.getUpdatedSlideItems(data.images, data.assets, isThumbnail, data.product) : null;
        var updatedParams = core.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                $thumbnailContainer.removeClass('d-none');
                $mainImageContainer.addClass('product-has-thumbnails col-lg-9');
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-lg-9');
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider',
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', core.slideIntoView);
        core.enableImageZoom($updatedSliderContainer);

        if (isThumbnail) {
            core.handleThumbnailArrows($updatedSliderContainer, slider);
        } else {
            // listen for videos getting updated from getUpdatedSlideItems function
            $('body').on('slider:videosLoaded', () => {
                core.handleSliderVideos($updatedSliderContainer, slider);
            });
        }
    });
}

module.exports = core;
