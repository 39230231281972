'use strict';

const core = require('core/components/header');

core.skipFooter = function(){
    $('.js-skip-to-footer').on('click', function () {
        $('.footer-item-title').focus();
    });
}

module.exports = core;
