'use strict';

var core = require('core/components/menu');

//voids header banner close functions
function headerBanner() { return };

 module.exports = Object.assign(core, {
    headerBanner
});
