'use strict';

module.exports = function () {
    $(".drag-switch").on("input change", event => {
        var target = event.target;
        var dragPosition = target.value;
        var $container = $(target).closest('.drag-switch');
        $container.css('--drag-position', `${dragPosition}%`);
    });
};
