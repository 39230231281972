'use strict'

var core = require('core/storeLocator/storeLocator');

window.googleMapsOnLoad = function () {
    window.googleMapsLoaded = true;
}

function init() {
    if ($('.map-canvas').data('has-google-api')) {
        var checkGoogleMapsLoadedInterval = setInterval(() => {
            if (window.googleMapsLoaded) {
                module.exports.methods.maps();
            }
            clearInterval(checkGoogleMapsLoadedInterval);
        }, 200);
    } else {
        $('.store-locator-no-apiKey').show();
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
 function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        if (typeof params[key] === 'string' && params[key].indexOf(',') > 0) {
            var multiKey = params[key].split(',');
            var multiParam = [];
            multiKey.forEach(function (singleKey) {
                multiParam.push(key + '=' + encodeURIComponent(singleKey));
            })
            return multiParam.join('&');
        }
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Uses google maps api to render a map
 * CUSTOM KM - customized for multi-select store types
 */
 function maps() {
    var map;
    var infowindow = new google.maps.InfoWindow();
    var mapdiv = $('.map-canvas').attr('data-locations');
    var bounds = new google.maps.LatLngBounds();
    var zoom = 4;

    mapdiv = JSON.parse(mapdiv);

    if (mapdiv.length === 1) {
        zoom = 18; // set zoom more specifically if single store
    }

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(37.09024, -95.712891);
    var mapOptions = {
        scrollwheel: false,
        zoom: zoom,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var label = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);

        var marker = new google.maps.Marker({
            position: storeLocation,
            type: item.storeType[0].value,
            map: map,
            title: item.name,
            icon: {
                url: item.backgroundImage,
                size: new google.maps.Size(45, 45),
                scaledSize: new google.maps.Size(45, 45),
                labelOrigin: new google.maps.Point(24, 17)
            },
            label: {
                text: label.toString(),
                color: item.color,
                fontSize: '14px'
            }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        // If there is only one store, center the map on that store
        if (mapdiv.length === 1) {
            map.setCenter(storeLocation);
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found, unless there is a single store result
    if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }
}

/**
 * Search for stores with new address data
 * uses google maps geocoding to get the lat and long for the address entered for the country
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
 function search(element) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var scope = element.closest('.store-locator-container');
    var $form = element.closest('.store-locator');
    var radius = scope.find('.results').data('radius');
    var address = $form.find('[name="searchText"]').val() ? $.trim($form.find('[name="searchText"]').val()) : '';
    var countryCode = $form.find('[name="countryCode"]').val() ? $.trim($form.find('[name="countryCode"]').val()) : '';
    var url = $form.attr('action');
    var urlParams = {
        radius: radius
    };

    var lat;
    var long;

    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({address: address, componentRestrictions: {country: countryCode}}, (results, status) => {
        if (status === 'OK') {
            lat = results[0].geometry.location.lat();
            long = results[0].geometry.location.lng();
            var payload = {
                lat: lat,
                long: long
            };

            url = module.exports.methods.appendToUrl(url, urlParams);
            $.ajax({
                url: url,
                type: $form.attr('method'),
                data: payload,
                dataType: 'json',
                success: function (data) {
                    module.exports.methods.updateStoresResults(data, scope);
                    scope.find('.select-store').prop('disabled', true);
                },
                complete: function () {
                    spinner.stop();
                }
            });
            return false;

        } else {
            alert("Geocode was not successful. Please narrow your search, and try again");
            spinner.stop();
        }
    });
}

core.filterResults = function () {
    var $filters = $('.filter-results [data-filter]');

    $filters.on('click', function (e) {
        e.preventDefault();
        var scope = $(this).closest('.store-locator-container');

        if ($(this).attr('data-filter') === 'all') {
            $filters.removeClass('active btn-primary').addClass('btn-secondary');
            $(this).addClass('active btn-primary').removeClass('btn-secondary');
        } else if ($(this).hasClass('btn-primary')) {
            $(this).removeClass('active btn-primary').addClass('btn-secondary');
        } else {
            $(scope).find('.btn.filter-all').removeClass('active btn-primary').addClass('btn-secondary');
            $(this).addClass('active btn-primary').removeClass('btn-secondary');
        }

        var activeFilters = $(scope).find('.filter-results .btn.active');
        var $filterType = $(this).attr('data-filter');
        if (activeFilters.length > 0) {
            var newFilter = "";
            for (var i = 0; i < activeFilters.length; i++) {
                if (i !== 0) {
                    newFilter += ',' + $(activeFilters[i]).attr('data-filter');
                } else {
                    newFilter = $(activeFilters[i]).attr('data-filter');
                }
            }
            $filterType = newFilter;
        } else {
            // If no filters are active, show all
            $('.filter-all').addClass('active btn-primary').removeClass('btn-secondary');
            $filterType = 'all';
        }
        var radius = scope.find('.results').data('radius');
        var searchKeys = scope.find('.results').data('search-key');
        var url = scope.find('.filter-results').data('action-url');
        var urlParams = {};

        if (searchKeys.postalCode) {
            urlParams = {
                radius: radius,
                postalCode: searchKeys.postalCode,
                storeType: $filterType
            };
        } else if (searchKeys.lat && searchKeys.long) {
            urlParams = {
                radius: radius,
                lat: searchKeys.lat,
                long: searchKeys.long,
                storeType: $filterType
            };
        }

        url = module.exports.methods.appendToUrl(url, urlParams);
        var dialog = scope.find('.radius').closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                module.exports.methods.updateStoresResults(data, scope);
                scope.find('.select-store').prop('disabled', true);
            },
            complete: function () {
                spinner.stop();
            }
        });

    });
}

function supportThisSalon() {
    var toast = require('core/components/toast');

    $('body').on('click', '[data-action-support-salon]', function (e) {
        var $btn = $(e.target);
        var refersionAffiliateId = $btn.data('refersion-affiliate-id');
        var url = $btn.data('action-url');

        $.post({
            url: url,
            data: {
                refersionAffiliateId: refersionAffiliateId
            },
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (data) {
                if (window.UncachedData.customer.isAuthenticated) {
                    toast.methods.show('success', data.successMessage);
                    $btn.closest('[data-refersion-support-salon-container]').html(data.newAffiliateStoreHtml);
                    if (data.existingAffiliateStoreModel) {
                        $(`.store-result#${data.existingAffiliateStoreModel.ID}`).find('[data-refersion-support-salon-container]').html(data.existingAffiliateStoreHtml);
                    }
                } else if (data.refersionAffiliate.link) {
                    window.location.href = data.refersionAffiliate.link;
                }
            },
            error: function (error) {
                toast.methods.show('danger', error.responseJSON.errorMessage);
            },
            complete: function () {
                $.spinner().stop();
            }
        });

    });
}

core.init = init;
core.supportThisSalon = supportThisSalon;
core.methods.search = search;
core.methods.maps = maps;
core.methods.appendToUrl = appendToUrl;
module.exports = core;
