module.exports = {
    init: function () {
        var $countryConfirmationModal = $('#countryConfirmationModal');

        if ($countryConfirmationModal.length > 0) {
            $countryConfirmationModal.find('.country-confirmation-see-all-regions').click(function (e) {
                $('#countryConfirmationModal').modal('hide');
                $('#localeModal').modal('show');
            })

            $countryConfirmationModal.modal('show');
        }
    }
};