var main = require('org_km/main');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.countryConfirmation = require('./components/countryConfirmation');
main.baseFiles.countrySelector = require('./components/countrySelector');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.base = require('./product/base');
main.baseFiles.cart = require('./cart');
main.baseFiles.refersion = require('./integrations/refersion/welcome');

var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
if (toggleObject.klaviyo_enabled) {
    main.baseFiles.klaviyo = require('./integrations/klaviyo/identify');
}

module.exports = main;
