'use strict';

const core = require('core/components/footer');
const scrollAnimate = require('core/components/scrollAnimate');

core.backToTop = function () {
    $('.back-to-top').click(function (e) {
        e.preventDefault();
        scrollAnimate();
    });
}

module.exports = core;
