'use strict';

var baseModule = require('core/product/tile');
const debounce = require('lodash/debounce');

baseModule.methods.swatchSwitch = el => {
    var $swatch = el;
    var $tileParent = $swatch.parents('.product-tile');
    var pid = $tileParent.attr('data-pid');
    var $tileImage = $tileParent.find('.product-tile-image');
    var $tileImageHover = $tileParent.find('.product-tile-image-secondary');
    var $tileImageParent = $tileImage.parents('.product-tile-image-container');

    $tileParent.find('.swatch.selected').removeClass('selected');
    $swatch.addClass('selected');

    //Replace main image and secondary image
    if($swatch.data('hover-image') && $swatch.data('hover-image') != '') {
        var swatchImageUrl = $swatch.data('hover-image');
        $tileImage.attr('src', swatchImageUrl);

        if($tileImageHover.length > 0 && $swatch.data('hover-image-secondary')) {
            var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
            $tileImage.data('alt-img-url', swatchSecondaryImageUrl);
            $tileImageHover.attr('src', swatchSecondaryImageUrl);
        } else if ($tileImageHover.length === 0 && $swatch.data('hover-image-secondary')) {
            var tileImageAlt = $tileImage.attr('alt');
            var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
            var imageHtml = '<img src="' + swatchSecondaryImageUrl + '" alt="' + tileImageAlt + '" class="tile-image product-tile-image-secondary" loading="auto"/>';
            $($tileImage).after(imageHtml);
            $tileImageParent.removeClass('no-secondary-image').addClass('show-secondary-image');
        } else if ($tileImageHover.length > 0 && $swatch.data('hover-image-secondary') === null) {
            $tileImage.data('alt-img-url', swatchImageUrl);
            $tileImageHover.attr('src', swatchImageUrl);
        }
    }

    //replace main link and Quickview link
    var swatchLink = $swatch.parents('.swatch-link');
    var splitSwatchLink = swatchLink.attr('href').split('?');
    var swatchLinkAttributes = splitSwatchLink[1];

    //main Image Link
    var mainImageLink = $tileParent.find('.product-tile-image-link');
    var mainImageLinkSplit = mainImageLink.attr('href').split('?');
    mainImageLink.attr('href', mainImageLinkSplit[0] + '?' + swatchLinkAttributes);

    //main Quickview Link
    var mainQuickViewLink = $tileParent.find('a.quickview');
    if (mainQuickViewLink.length) {
        // strip pid from params for quickview (gets added when Storefront URLs are disabled)
        var quickviewSwatchUrl = new URL(swatchLink.attr('href'), window.location.origin);
        quickviewSwatchUrl.searchParams.delete('pid');
        var quickviewQueryString = quickviewSwatchUrl.search.split('?')[1];

        mainQuickViewLink.attr('href', mainQuickViewLink.attr('href').split('?')[0] + '?' + 'pid=' + pid + '&' + quickviewQueryString);
    }

    //Shop Now Button
    var shopButton = $tileParent.find('.product-tile-shop-button');
    if (shopButton.length) {
        var shopButtonSplit = shopButton.attr('href').split('?');
        shopButton.attr('href', shopButtonSplit[0] + '?' + swatchLinkAttributes);
    }

    // send custom event for updating transactional elements at the variant level
    $('body').trigger('productTile:afterSwatchSwitch', {swatch: $swatch, tileParent: $tileParent});
}

// https://stackoverflow.com/a/8495740
function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

function alignProductGridTiles() {
    const numOfCols = window.matchMedia("(min-width: 768px)").matches ? 4 : 2;
    const $productSearchResultsContainer = $('#product-search-results');
    const $productTileBodyContainers =  $productSearchResultsContainer.find('.product-grid .tile-body').not('.product-grid .product-rows .tile-body');
    const productTileBodyContainers = $productTileBodyContainers.toArray();
    const productTileBodyContainersPerRow = sliceIntoChunks(productTileBodyContainers, numOfCols);

    productTileBodyContainersPerRow.forEach((row) => {
        let max = 0;

        row.forEach((container) => {
            $(container).css('height', '');
            const height = $(container).height();
            if (height > max) {
                max = height;
            }
        });
        row.forEach((container) => {
            $(container).height(max);
        });
    });
}

baseModule.alignProductGrid = function () {
    alignProductGridTiles()

    $(window).on('resize', debounce(() => alignProductGridTiles(), 500));

    const observer = new MutationObserver(alignProductGridTiles);

    const $productSearchResultsContainer = $('#product-search-results');
    if ($productSearchResultsContainer.length) {
        const productSearchResultsContainerNode = $productSearchResultsContainer.get(0);
        observer.observe(productSearchResultsContainerNode, {
            childList: true,
            attributes: false,
            subtree: true
        });
    }
}

function alignProductSliderTiles() {
    const $productSliderContainers = $('.slider-container');

    $productSliderContainers.each((index, element) => {
        const $productTileBodyContainers =  $(element).find('.product .tile-body');
        const productTileBodyContainers = $productTileBodyContainers.toArray();
        let max = 0;

        productTileBodyContainers.forEach((container) => {
            $(container).css('height', '');
            const height = $(container).height();
            if (height > max) {
                max = height;
            }
        });

        productTileBodyContainers.forEach((container) => {
            $(container).height(max);
        });
    });

}

baseModule.alignProductSliders = function () {
    alignProductSliderTiles()

    $(window).on('resize', debounce(() => alignProductSliderTiles(), 500));

    const silderObserver = new MutationObserver(debounce(() => alignProductSliderTiles(), 100));
    const $productSliderContainers = $('.slider-container');

    $productSliderContainers.each((index, element) => {
        if (element) {
            silderObserver.observe(element, {
                childList: true,
                attributes: false,
                subtree: true
            });
        }
    });

    const $tabPanes = $productSliderContainers.closest('.tab-pane');
    const tabObserver = new MutationObserver(alignProductSliderTiles);

    $tabPanes.each((index, element) => {
        if (element) {
            tabObserver.observe(element, {
                childList: false,
                attributes: true,
                subtree: false
            });
        }
    });
}

baseModule.expandableSizeSwatchGroups = function () {
    $('.search-results').on('click', '.product-tile .gradient-dismiss, .product-tile .gradient-group', function(e) {
        e.preventDefault();
        $(this)
            .parents('.collapsed')
            .removeClass('collapsed');
    });
};

baseModule.handleQuantityStepperTab = function() {
    $('.search-results').on('keydown', "input[name='qty-stepper']", function (e) {
        var keyCode = e.keyCode || e.which;

        // if keydown was a tab key
        if (keyCode == 9) {
            var $this = $(this);

            // reverse tabbing when shift key is also pressed at the same time
            if (e.shiftKey) {
                //get the next stepper element
                var $prevStepper = $this.parents('.swatch-quantity-add-cart-container').prev().find("input[name='qty-stepper']");

                //if no stepper found check in the next product for the first one
                if (!$prevStepper.length) {
                    $prevStepper = $this.parents('.product-rows').prev().find("input[name='qty-stepper']").last();
                }

                //focus on next stepper
                if ($prevStepper.length) {
                    e.preventDefault();
                    $prevStepper.focus();
                }
            //forward tabbing
            } else {
                //get the next stepper element
                var $nextStepper = $this.parents('.swatch-quantity-add-cart-container').next().find("input[name='qty-stepper']");

                //if no stepper found check in the next product for the first one
                if (!$nextStepper.length) {
                    $nextStepper = $this.parents('.product-rows').next().find("input[name='qty-stepper']").first();
                }

                //focus on next stepper
                if ($nextStepper.length) {
                    e.preventDefault();
                    $nextStepper.focus();
                }
            }

            //if the product is in a collapsed state expand it so you can see all the steppers
            $this
                .parents('.collapsed')
                .removeClass('collapsed');
        }
    });
};

module.exports = baseModule;
