'use strict';

/**
 * Handles Closing of Refersion Welcome Overlay
 */
function welcome() {
    var $refersionWelcomeModal = $('#refersionWelcomeModal');

    $refersionWelcomeModal.modal('show');

    setTimeout(() => {
        $refersionWelcomeModal.modal('hide');
    }, window.UncachedData.refAffiliateOverlayDisplayDuration);
}

module.exports = {
    welcome: welcome
};
